import react, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

export default function Header() {
  // Add the background color and sticky effect on scroll
  const location = useLocation();
  const {type} = useParams();
  console.log("this is type",type)
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById("navbar");
      if (window.scrollY > 100) {
        navbar.classList.add("sticky-navbar");
      } else {
        navbar.classList.remove("sticky-navbar");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function addBackground() {
    setTimeout(() => {
      const navbarSupportedContent = document.getElementById(
        "navbarSupportedContent"
      );
      const navbar = document.getElementById("navbar");
      if (navbarSupportedContent.classList.contains("show")) {
        navbar.classList.add("dynamic-background");
      } else {
        navbar.classList.remove("dynamic-background");
      }
    }, 400);
  }
// Check if bg-dark should be applied based on the current route
const isBlogRoute = location.pathname.includes("/blogs") || location.pathname.includes("/blog/");
  return (
    <div>
      <nav
         className={`navbar navbar-expand-lg custom-navbar ${isBlogRoute ? "bg-dark" : ""}`}
        id="navbar"
      >
        <div className="container position-relative">
          <a className="navbar-brand d-block d-lg-none" href="#">
            <img src="Images/logo in png.png" alt />
          </a>
          <a className="navbar-brand d-none d-block d-lg-block" href="#">
            <img src="Img/logoo.png" alt />
          </a>
          <button
            onClick={addBackground}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link fw-semibold p-4 navbar-padding text-white"
                  to="/home"
                >
                  Home
                </Link>
              </li>

              <Link
                className="nav-link fw-semibold p-4 navbar-padding text-white"
                to="/features-area"
              >
                Features
              </Link>
              <Link
                className="nav-link fw-semibold p-4 navbar-padding text-white"
                to="/screenshot-area"
              >
                Screenshot
              </Link>
              <Link
                className="nav-link fw-semibold p-4 navbar-padding text-white"
                to="/contact-area"
              >
                Contact
              </Link>
              <Link
                className="nav-link fw-semibold p-4 navbar-padding text-white"
                to="/blogs"
              >
                Blogs
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
