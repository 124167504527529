/* eslint-disable no-unused-vars */
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Outlet } from "react-router-dom";
import Main from "../Component/Main";

export default function LandingPage() {
  return (
    <div className="main">
      <Main />
    </div>
  );
}
