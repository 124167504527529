import react from 'react';

export default function Footer(){
return(
   <footer className="d-flex justify-content-center justify-content-md-between align-items-center flex-wrap flex-lg-nowrap">
  <div className="text-white text-center mx-2 my-1 my-md-0">© Copyrights 2024 Soothfy All rights reserved.</div>
  <div className="footer-links mx-2 my-1 my-md-0">
    <a className="px-2" href="https://soothfy.com/privacy_policy.html" target="_blank">Privacy-Policy</a>
    <a className="px-2" href="https://soothfy.com/terms_and_conditions.html" target="_blank">Terms &amp;
      Conditions</a>
  </div>
</footer>

)
}