/* eslint-disable no-unused-vars */
import logo from "./logo.svg";
import "./App.css";
import LandingPage from "./Landingpage/Landingpage";
import {
  BrowserRouter,
  Route,
  Switch,
  Routes,
  HashRouter,
  Navigate,
} from "react-router-dom";
import BlogList from "./Component/blog/blog";
import BlogDetails from "./Component/blog/blog-details";
import Header from "./Component/Header";
import Footer from "./Component/Footer";
import Main from "./Component/Main";
import MainLayout from "./layout/mainLayout";

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/" element={<MainLayout />}>
            <Route path="/home" element={<LandingPage />} />
            <Route path="/:type" element={<LandingPage />} />
            <Route path="blogs" element={<BlogList />} />
            <Route path="blog/:id" element={<BlogDetails />} />
            <Route path="*" element={<LandingPage />} />
          </Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
