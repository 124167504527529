/* eslint-disable jsx-a11y/alt-text */
import react from "react";

export default function Customerreview() {
  return (
    <section className="section ptb_100 customer-reviews">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <span className="d-inline-block rounded-pill shadow-sm px-4 py-2 mb-3">
                <span className="fw-bold"><img src="Images/premium.svg" className="premium-logo" alt /></span>
                <span className="fw-bold"> Early Access </span>
                Reviews
              </span>
              <h2 className="fw-bold text-capitalize d-none d-md-block">What Our mental worrior Are Saying</h2>
              <h3 className="fw-bolder fs-3 d-block d-md-none">What Our mental worrior Are Saying</h3>
              <p className="d-none d-sm-block mt-4 fw-normal">We are currently offering an Early Access version of our app for experimentation, and we have received a testimonial from one of our enthusiastic users.</p>
              <p className="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing
                elit
                Laborum obcaecati.</p>
            </div>

          </div>
        </div>
        <div className="row text-start">
          <div className="col-12 col-md-6 col-lg-4 res-margin mb-4">
            <div className="single-review card ">
              <div className="card-top p-4 ">
                <div className="stars-div">
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                </div>
                <h4 class="mt-4 mb-3 text-orange">really game-changer!!</h4>

                <div class="review-text">
                  <p>
                    "As someone who struggles with stress and anxiety, this app has been a game-changer for me. The soothing voice, coupled with the calming music, helps me find my inner peace effortlessly."
                  </p>
                </div>
                <div className="quote-icon">
                  <img src="Images/quote-icon.png" alt />
                </div>
              </div>
              <div className="reviewer media p-4">
                <div className="review-thumb">
                  <img
                    src="Images/user.jpg"
                    alt
                    className="avatar-lg rounded-circle"
                  />
                </div>
                <div className="reviewer-card media-body align-self-center ms-4">
                  <h5 className="reviewer-name mb-2 fw-bolder">Resma Keshav</h5>
                  <h6 className="reviewer-pos">Bengaluru , India</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 res-margin mb-4">
            <div className="single-review card">
              <div className="card-top p-4">
                <div className="stars-div">
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                </div>
                <h4 class="mt-4 mb-3 text-orange">significant improvement </h4>

                <div class="review-text">
                  <p>
                    "Even in just a few days of using this app, I've noticed a significant improvement in my focus and overall well-being. Highly recommended for anyone seeking mental clarity."
                  </p>
                </div>
                <div className="quote-icon">
                  <img src="Images/quote-icon.png" alt />
                </div>
              </div>
              <div className="reviewer media p-4">
                <div className="review-thumb">
                  <img
                    src="Images/reviewer-img.png"
                    alt
                    className="avatar-lg rounded-circle"
                  />
                </div>
                <div className="reviewer-card media-body align-self-center ms-4">
                  <h5 className="reviewer-name mb-2 fw-bolder">Robin Wang</h5>
                  <h6 className="reviewer-pos">New Jersey, USA</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="single-review card">
              <div className="card-top p-4">
                <div className="stars-div">
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                  <img src="Images/star.svg" alt />
                </div>
                <h4 class="mt-4 mb-3 text-orange">App for regular use</h4>

                <div class="review-text">
                  <p>
                    "I never realized how much tension I was carrying until I started using this app regularly. Now, I feel lighter, more centered, and better equipped to handle whatever life throws my way."
                  </p>
                </div>
                <div className="quote-icon">
                  <img src="Images/quote-icon.png" alt />
                </div>
              </div>
              <div className="reviewer media p-4">
                <div className="review-thumb">
                  <img
                    src="Images/reviwer-3.png"
                    alt
                    className="avatar-lg rounded-circle"
                  />
                </div>
                <div className="reviewer-card media-body align-self-center ms-4">
                  <h5 className="reviewer-name mb-2 fw-bolder">Juniad SMITH </h5>
                  <h6 className="reviewer-pos">Virginia, USA</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
