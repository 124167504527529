import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainLayout from "../../layout/mainLayout";

const BlogDetails = () => {
  const { id } = useParams(); // Get the blog ID from the URL
  const [blogs, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const tags = ["React", "UI/UX", "Microinteraction"];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const res = await axios.get(`https://www.soothfy.com/v1/webBlog/${id}`);

        setBlog(res?.data?.blog);
      } catch (error) {
        console.error("Error fetching blog details");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [id]);

  return (
    <>
      {" "}
      <div
        className="blog-details-container container 100vh"
        style={{ marginTop: "130px" }}
      >
        <div className="blog-details-header row m-0">
          <div className="blog-details-header-content col-8">
            <h1 className="blog-details-title">{blogs?.title}</h1>
            <p className="blog-details-author">
              {/* Written by <strong>{blogs?.author}</strong>  */}|{" "}
              {formatDate(blogs?.createdAt)}
            </p>
            <div className="blog-details-footer">
              <div className="blog-details-tags">
                {/* {tags?.map((tag, index) => (
          <span key={index} className="blog-details-tag">
            {tag}
          </span>
        ))} */}
              </div>
            </div>
          </div>
          <div className="m-0 col-4">
            <img
              src={blogs?.image}
              alt="Blog"
              className="blog-details-banner-img m-0"
            />
          </div>
        </div>

        <div className="blog-details-content">
          <div
            dangerouslySetInnerHTML={{ __html: blogs?.description }}
            className="blog-details-html-content text-start"
          />
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
