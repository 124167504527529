/* eslint-disable jsx-a11y/alt-text */
import react, { useEffect } from "react";

export default function Screenshot() {
  useEffect(() => {
    // Slider code starts
    const track = document.querySelector(".carousel-track");
    const slides = Array.from(track.children);

    const slideWidth = slides[0].getBoundingClientRect().width;

    // Set the width of the track to accommodate all slides
    track.style.width = `${slideWidth * slides.length}px`;

    // Arrange slides next to each other
    slides.forEach((slide, index) => {
      slide.style.left = `${slideWidth * index}px`;
    });

    // Move slides
    const moveSlides = () => {
      const firstSlide = track.children[0];

      // Apply transition to enable smooth movement
      track.style.transition = "transform 0.5s ease-in-out";
      track.style.transform = `translateX(${-slideWidth}px)`;

      // Reset transition and move first slide to the end
      const transitionEnd = () => {
        track.style.transition = "none";
        track.style.transform = "translateX(0)";
        track.appendChild(firstSlide);
        track.removeEventListener("transitionend", transitionEnd);
      };

      track.addEventListener("transitionend", transitionEnd);
    };

    setInterval(moveSlides, 4000); // Move slides every 2 seconds
  }, []);
  return (
    <section
      id="screenshot-area"
      className="section screenshot-area ptb_100 bg-lightPink"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <span className="d-inline-block rounded-pill shadow-sm px-4 py-2 mb-3">
                <span>
                  <img src="Images/premium.svg" className="premium-logo" alt />
                </span>
                <span className="fw-bold">Awesome </span>
                Interface
              </span>
              <h2 className="fw-bold text-capitalize d-none d-md-block">
                Simple and beautiful interface
              </h2>
              <h3 className="fw-bolder fs-3 d-block d-md-none">
                Simple and beautiful interface
              </h3>
              <p className="d-none d-sm-block mt-4 fw-normal">
              Our app is designed with simplicity in mind, providing a clean and modern layout. Everything you need is easy to find, making your experience smooth and enjoyable. 
             
              </p>
              <p className="d-block d-sm-none mt-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit Laborum
                obcaecati.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="carousel-container">
                <div className="carousel-track">
                  <div className="carousel-slide">
                    <img src="Images/CHANGE THIS IMAGE 1.png" alt />
                  </div>
                  <div className="carousel-slide">
                    <img src="Images/CHANGE THIS IMAGE 2.png" alt />
                  </div>
                  <div className="carousel-slide">
                    <img src="Images/CHANGE THIS IMAGE 3.png" alt />
                  </div>
                  <div className="carousel-slide">
                    <img src="Images/CHANGE THIS IMAGE 4.png" alt />
                  </div>
                  <div className="carousel-slide">
                    <img src="Images/CHANGE THIS IMAGE 5.png" alt />
                  </div>
                  <div className="carousel-slide">
                    <img src="Images/CHANGE THIS IMAGE 6.png" alt />
                  </div>
                  <div className="carousel-slide">
                    <img src="Images/CHANGE THIS IMAGE 7.png" alt />
                  </div>
                  <div className="carousel-slide">
                    <img src="Images/CHANGE THIS IMAGE 8.png" alt />
                  </div>
                  <div className="carousel-slide">
                    <img src="Images/CHANGE THIS IMAGE 9.png" alt />
                  </div>
                </div>
                <img
                  src="Images/iphonemockup.png"
                  alt
                  className="center-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
