import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const BlogList = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const handleCardClick = (id) => {
    navigate(`/blog/${id}`);
  };

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(
        "https://www.soothfy.com/v1/webBlog/list/web"
      ); // Replace with your API URL
      setBlogs(response?.data?.blogs);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };
  useEffect(() => {
    // API call to fetch blog data

    fetchBlogs();
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };
  return (
    <div className="container " style={{ marginTop: "110px",minHeight:"100vh" }}>
      <h3 className="mb-5 text-start">Blogs</h3>
      <div className="row ">
        {blogs?.map((blog) => (
          <div className="col-sm-6 col-xl-4 mb-4 col-12" key={blog?._id}>
            <div className="blog-card ">
              <div className="blog-card-img-holder">
                <img src={blog?.image} alt="Blog" />
              </div>
              <h3
                className="blog-title cursor-pointer"
                onClick={() => handleCardClick(blog?._id)}
              >{`${blog?.title.slice(0, 60)}${
                blog?.title?.length > 60 ? "..." : ""
              }`}</h3>
              <div
                className="d-flex justify-content-between"
                style={{ padding: "1rem" }}
              >
                {" "}
                <div>
                  {" "}
                  <span className="blog-time">
                    {formatDate(blog?.createdAt)}
                  </span>
                </div>
                {/* <p className="blog-description"> */}
                {/* <div
               dangerouslySetInnerHTML={{ __html: blog?.description }}
               className="blog-details-html-content text-start text-truncate"
             /> */}
                {/* </p> */}
                <div className="blog-options ">
                  <span
                    className="cursor-pointer blog-options"
                    onClick={() => handleCardClick(blog?._id)}
                  >
                    View Blog{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogList;
