/* eslint-disable jsx-a11y/alt-text */
import react from "react";

export default function Discoversection() {
  return (
    <section className="section discover-area overflow-hidden ptb_100 bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 order-2 order-lg-1">
            <div className="service-thumb discover-thumb mx-auto pt-5 pt-lg-0 text-lg-center">
              <img src="Img/discover-area.png" alt />
            </div>
          </div>
          <div className="col-12 col-lg-6 order-1 order-lg-2 text-start">
            <div className="pt-4 pt-lg-0">
              <h2 className="pb-5 pb-sm-0 d-none d-md-block">
                Why Choose Soothfy?
              </h2>
              <h3 className="fw-bolder fs-3 d-block d-md-none">
                Why Choose Soothfy?
              </h3>
              <p className="d-none d-sm-block py-2">
                Your personalized meditation companion, guiding you through
                yoga, meditation, and relaxation for inner peace and calm.
              </p>
              <ul className="check-list">
                <li className="py-1">
                  <div className="list-box media">
                    <span className="icon align-self-center rightTickSpan">
                      <img src="Images/RightTick.svg" alt />
                    </span>
                    <span className="media-body ps-3">
                      <p>Easy to use interface.</p>
                    </span>
                  </div>
                </li>
                <li className="py-1">
                  <div className="list-box media">
                    <span className="icon align-self-center rightTickSpan">
                      <img src="Images/RightTick.svg" alt />
                    </span>
                    <span className="media-body ps-3">
                      <p>
                        Comprehensive features addressing various aspects of
                        mental health.
                      </p>
                    </span>
                  </div>
                </li>
                <li className="py-1">
                  <div className="list-box media">
                    <span className="icon align-self-center rightTickSpan">
                      <img src="Images/RightTick.svg" alt />
                    </span>
                    <span className="media-body ps-3">
                      <p>Evidence-based practices and techniques.</p>
                    </span>
                  </div>
                </li>
                <li className="py-1">
                  <div className="list-box media">
                    <span className="icon align-self-center rightTickSpan">
                      <img src="Images/RightTick.svg" alt />
                    </span>
                    <span className="media-body ps-3">
                      <p>Regular updates and new content additions.</p>
                    </span>
                  </div>
                </li>
                <li className="py-1">
                  <div className="list-box media">
                    <span className="icon align-self-center rightTickSpan">
                      <img src="Images/RightTick.svg" alt />
                    </span>
                    <span className="media-body ps-3">
                      <p>Get started today.</p>
                    </span>
                  </div>
                </li>
              </ul>
              <div className="service-icon">
                <span>
                  <img src="img/discover-area-icon-1.svg" alt />
                </span>
                <span>
                  <img src="img/discover-area-icon-2.svg" alt />
                </span>
                <span>
                  <img src="img/discover-area-icon-3.svg" alt />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
