/* eslint-disable jsx-a11y/alt-text */


export default function Fearturesection() {
 
  return (
    <section id="features-area" className="features-area ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <span className="d-inline-block rounded-pill shadow-sm px-4 py-2 mb-3">
                <span>
                  <img src="Images/premium.svg" className="premium-logo" alt />
                </span>
                <span className="fw-bold">Premium </span>
                Features
              </span>
              <h2 class="fw-bold font-style d-none d-md-block">What Makes Soothfy Different?</h2>
              <h3 class="fw-bolder fs-3 d-block d-md-none">What Makes Soothfy Different?</h3>

              <p class="d-none d-sm-block mt-4 fw-normal text-black font-style">We worked extensively with mental health advocates to develop a customized wellness app, tailor-made for each user. It aimed to strike a balance by prioritizing mental well-being and providing personalized features.</p>

              <p class="d-block d-sm-none mt-4 font-style">We worked extensively with mental health advocates to develop a customized wellness app, tailor-made for each user. It aimed to strike a balance by prioritizing mental well-being and providing personalized features.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row text-center">
          <div className="col-12 col-md-6 col-lg-4 res-margin mb-4">
            <div className="image-box text-center p-5 animate max-height">
              {/* Featured Image */}
              <div className="featured-img mb-3">
                {/* <img class="avatar-sm" src="Images/first.png" alt=""> */}
                <img
                  className="avatar-sm"
                  src="Images/Personalized Routines.svg"
                  alt
                />
              </div>
              {/* Icon Text */}
              <div className="icon-text">
                <h3 className="mb-2 font-style">Personalized Routines</h3>
                <p className="font-style"></p>
                <p>
                  Tailored routines designed to address major mental health
                  concerns such as stress, anxiety, depression, and more.
                </p>
                <p />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 res-margin mb-4">
            <div className="image-box text-center p-5 animate max-height">
              {/* Featured Image */}
              <div className="featured-img mb-3">
                {/* <img class="avatar-sm" src="Images/second.png" alt=""> */}
                <img
                  className="avatar-sm"
                  src="Images/Meditation Sessions.svg"
                  alt
                />
              </div>
              {/* Icon Text */}
              <div className="icon-text">
                <h3 className="mb-2">Meditation Sessions</h3>
                <p>
                  Access a variety of meditation sessions, including guided
                  meditation, full-body scans, and breathing exercises.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 res-margin mb-4">
            <div className="image-box text-center p-5 animate max-height">
              {/* Featured Image */}
              <div className="featured-img mb-3">
                {/* <img class="avatar-sm" src="Images/third.png" alt=""> */}
                <img className="avatar-sm" src="Images/soundscapes.svg" alt />
              </div>
              {/* Icon Text */}
              <div className="icon-text">
                <h3 className="mb-2">Soundscapes</h3>
                <p>
                  Relaxing sounds and music to help users unwind and improve
                  their mental well-being.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 res-margin mb-4">
            <div className="image-box text-center p-5 animate max-height">
              {/* Featured Image */}
              <div className="featured-img mb-3">
                {/* <img class="avatar-sm" src="Images/third.png" alt=""> */}
                <img
                  className="avatar-sm"
                  src="Images/Dietary Guidance.svg"
                  alt
                />
              </div>
              {/* Icon Text */}
              <div className="icon-text">
                <h3 className="mb-2">Dietary Guidance</h3>
                <p>
                  Nutritional recommendations and tips to support overall mental
                  health.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 res-margin mb-4">
            <div className="image-box text-center p-5 animate max-height">
              {/* Featured Image */}
              <div className="featured-img mb-3">
                {/* <img class="avatar-sm" src="Images/third.png" alt=""> */}
                <img
                  className="avatar-sm"
                  src="Images/community support.svg"
                  alt
                />
              </div>
              {/* Icon Text */}
              <div className="icon-text">
                <h3 className="mb-2">Community Support</h3>
                <p>
                  Connect with like-minded individuals, share experiences, and
                  support each other on your mental health journey.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 res-margin mb-4">
            <div className="image-box text-center p-5 animate max-height">
              {/* Featured Image */}
              <div className="featured-img mb-3">
                {/* <img class="avatar-sm" src="Images/third.png" alt=""> */}
                <img
                  className="avatar-sm"
                  src="Images/Progress Tracking.svg"
                  alt
                />
              </div>
              {/* Icon Text */}
              <div className="icon-text">
                <h3 className="mb-2">Progress Tracking</h3>
                <p>
                  Keep track of your mental wellness journey with built-in
                  progress tracking and analytics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
