import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Homesection from "./Home-section";
import Fearturesection from "./Feature-area";
import Discoversection from "./Discover-area";
import Workarea from "./work-area";
import Screenshot from "./Screen-shot";
import Customerreview from "./Customer-review";
import Download from "./Download";
import Contactus from "./Contactus";

export default function Main() {
  const location = useLocation();
  const { type } = useParams();
  // Function to scroll to the specific section
  const scrollToSection = (sectionId) => {
    setTimeout(()=>{
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },100)
    
  };
  // Scroll to the section based on the current hash when the component mounts
  useEffect(() => {
    if (type) {
      scrollToSection(type);
    } else {
      scrollToSection("home"); // Default to Home if no section is specified
    }
  }, [type]);

  return (
    <>
      <section id="home">
        <Homesection />
      </section>
      <section id="features-area">
        <Fearturesection />
      </section>
      <section id="discover-section">
        <Discoversection />
      </section>
      <section id="work-area">
        <Workarea />
      </section>
      <section id="screenshot-section">
        <Screenshot />
      </section>
      <section id="customer-review">
        <Customerreview />
      </section>
      <section id="download-section">
        <Download />
      </section>
      <section id="contact-area">
        <Contactus />
      </section>
    </>
  );
}
