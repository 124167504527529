/* eslint-disable jsx-a11y/alt-text */
import react from 'react';

export default function Homesection() {
  return (
    <section className="home-section welcome-area bg-overlay overflow-hidden" id='home'>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-6">
            <div className="welcome-intro text-start">
              <h2 className="text-capitalize mb-4 fst-italic fs-3 text-white d-none d-md-block "> <span className="text-orange">Welcome to
                Soothfy </span> <br /> your
                companion for meditation and holistic wellness.</h2>
              <h3 className="text-white fw-bolder fst-italic d-block d-md-none "><span className="text-orange">Welcome to
                Soothfy </span> <br /> your
                companion for meditation and holistic wellness.</h3>
              <p className="my-4 text-white fst-italic">At Soothy, we're committed to empowering individuals
                to live
                healthier, more balanced lives through the practice of meditation and mindfulness.
                Access
                a library of guided meditation sessions tailored to your needs, including stress relief,
                focus enhancement, and sleep support.</p>
              <p className="my-4 text-white fst-italic">Available On </p>
              <div className="button-group store-buttons d-flex justify-content-start my-4 py-4 flex-wrap">
                <a href ="https://play.google.com/store/apps/details?id=com.soothfy&pli=1" target="_blank" >
                  <img src="Images/google-play png.png" className="img-fluid me-md-2 download-btn" alt />
                </a>
                <a href ="https://apps.apple.com/us/app/soothfy/id6654909520" target='_blank'>
                  <img src="Images/app-store png.png" className="img-fluid download-btn" alt />
                </a>
              </div>
            </div>

          </div>
          <div className="col-12 col-md-5 col-lg-6">
            <div className="welcome-thumb mx-auto">
              <img src="Img/Home-Phone-Icon.png" alt />
            </div>
          </div>
        </div>
      </div>
      <div className="shape-bottom">
        <svg
          viewBox="0 0 1920 310"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className="svg replaced-svg"
        >
          <title>Soothfy Shape</title>
          <desc>Created with Sketch</desc>
          <defs />
          <g
            id="Soothfy-Landing-Page"
            stroke="none"
            strokeWidth={1}
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Soothfy-v1.0"
              transform="translate(0.000000, -554.000000)"
              fill="#FFFFFF"
            >
              <path
                d="M-3,551 C186.257589,757.321118 319.044414,856.322454 395.360475,848.004007 C509.834566,835.526337 561.525143,796.329212 637.731734,765.961549 C713.938325,735.593886 816.980646,681.910577 1035.72208,733.065469 C1254.46351,784.220361 1511.54925,678.92359 1539.40808,662.398665 C1567.2669,645.87374 1660.9143,591.478574 1773.19378,597.641868 C1848.04677,601.75073 1901.75645,588.357675 1934.32284,557.462704 L1934.32284,863.183395 L-3,863.183395"
                id="Soothfy-v1.0"
              />
            </g>
          </g>
        </svg>
      </div>
    </section>
  )
}