/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import react from "react";

export default function Download() {
  return (
    <section className="section download-area overlay-dark ptb_100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-9">
            <div className="download-text text-center">
              <h2 className="d-none d-md-block text-white">Soothfy is available for all devices</h2>
              <h3 className="fw-bolder fs-3 d-block d-md-none text-white">Soothfy is available for all
                devices
              </h3>
              <p className="my-3 d-none d-sm-block">We are excited to announce the release of the Soothfy Mental Health App! After thorough beta testing with dedicated mental health warriors, the app is now available for everyone. </p>
              <p className="my-3 d-block d-sm-none">We are excited to announce the release of the Soothfy Mental Health App! After thorough beta testing with dedicated mental health warriors, the app is now available for everyone. </p>
              <div className="button-group store-buttons d-flex justify-content-center my-4 flex-wrap">
                <a href ="https://play.google.com/store/apps/details?id=com.soothfy&pli=1" target="_blank" >
                  <img src="Images/google-play png.png" className="img-fluid me-md-2 download-btn" alt />
                </a>
                <a href ="https://apps.apple.com/us/app/soothfy/id6654909520" target='_blank'>
                  <img src="Images/app-store png.png" className="img-fluid download-btn" alt />
                </a>
              </div>
              <span className="d-lnline-block fw-3 fst-italic">* Available on iPhone,
                iPad and all Android devices </span>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}
