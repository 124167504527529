/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import react, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
export default function Contactus() {
  function showSuccess() {
    return Swal.fire({
      title: `Thank you for contacting us!`,
      text: `We will reach out to you soon.`,
      padding: "1em",
      borderRadius: "20px",
      color: "#000",
      confirmButtonColor: "rgb(246 120 106)",
      confirmButtonText: "Close",
      icon: "success", // Adding success icon here
      showCloseButton: true,
      customClass: {
        container: 'swal-border-radius', // Define custom class for border radius
      },
      backdrop: `
        rgba(0,0,0,0.5)
      `,
    });
  }
  
  const initialValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.subject) {
      errors.subject = "Required";
    }
    if (!values.message) {
      errors.message = "Required";
    }
    return errors;
  };

  const handleSubmit = (values, { setSubmitting, resetForm  }) => {
    const route = "https://www.soothfy.com/v1/contact/addContactUs";
    axios
      .post(route, values)
      .then((response) => {
        console.log("Form submitted successfully", response);
        showSuccess()
        setSubmitting(false);
        resetForm();
      })
      .catch((error) => {
        console.error("There was an error submitting the form", error);
        setSubmitting(false);
      });
  };
  // const  type  = useLocation();
  // console.log("this is useparam" ,type)
  // // Function to scroll to the specific section
  // const scrollToSection = (sectionId) => {
  //   setTimeout(()=>{
  //     const section = document.getElementById(sectionId);
  //     if (section) {
  //       section.scrollIntoView({ behavior: "smooth" });
  //     }
  //   },100)
    
  // };
  // useEffect(()=>{
  //   scrollToSection("contact-area")
  // },[type.pathname])
  return (
    <section id="contact-area" className="section contact-area bg-gray ptb_100">
      <div className="container justify-content-center">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="section-heading text-center">
              <h2 className="text-capitalize d-none d-md-block">
                Stay in sync with us
              </h2>
              <h3 className="fw-bolder fs-3 d-block d-md-none">
                Stay in sync with us
              </h3>
              <p className="d-none d-sm-block mt-4">
                Stay up-to-date with the latest news, updates, and exclusive
                offers from Soothfy. Join our newsletter to receive regular
                insights on meditation, mindfulness, and holistic well-being.
              </p>
              <p className="d-block d-sm-none mt-4">
                Stay up-to-date with the latest news, updates, and exclusive
                offers from Soothfy.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-between align-items-around text-center text-md-start">
          <div className="col-12 col-md-5">
            <div className="social-media-icons mb-5 pt-md-3 mt-md-3">
              <p className="mb-3">
                Stay connected with us for updates, inspiration, and more!
                Follow us on social media to join our community and journey
                together.
              </p>
              <ul className="d-flex justify-content-center justify-content-md-start align-items-center">
                <li className="py-2">
                  <a
                    className="media"
                    href="https://x.com/Soothfyapp"
                    target="_blank"
                  >
                    <div className="social-icon me-3 my-auto">
                      <img
                        src="Img/Twitter-icon.svg"
                        alt
                        className="contact-icon-img"
                      />
                    </div>
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="media"
                    href="https://www.instagram.com/soothfyapp/"
                    target="_blank"
                  >
                    <div className="social-icon me-3 my-auto">
                      <img
                        src="Img/insta-icon.svg"
                        alt
                        className="contact-icon-img"
                      />
                    </div>
                  </a>
                </li>
                <li className="py-2">
                  <a
                    className="media"
                    href="https://www.linkedin.com/company/soothfyapp/"
                    target="_blank"
                  >
                    <div className="social-icon me-3 my-auto">
                      <img
                        src="Img/Linkedin-icon.svg"
                        alt
                        className="contact-icon-img"
                      />
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="contact-email pt-2 pt-md-4">
              <p>
                Have any questions, suggestions, or feedback? We'd love to hear
                from you! Please feel free to email us for prompt assistance and
                support.
              </p>
              <p>
                {/* <div className="row">
                    <div className="col-lg-2 col-6">
                        <div className="fw-bold">Email :</div>
                        <div className="fw-bold">Company:</div>
                        <div className="fw-bold">Address:</div>
                    </div>

                    <div className="col-lg-9 col-6">
                        <div>info@soothfy.com</div>
                        <div>Syntrum Moderator Private Limited</div>
                        <div>Office No M1, 135, EA, Ring Rd, opp. Bombay Hospital Service
                        Road, Scheme No:94, Indore, Madhya Pradesh 452010</div>
                    </div>
                </div> */}
                <div>
                  <strong>Email:</strong> <span> info@soothfy.com</span>
                </div>
                <div>
                  <strong>Company:</strong>{" "}
                  <span> Syntrum Moderator Private Limited</span>
                  <strong> </strong>
                </div>
                <div>
                  <strong>Address:</strong>{" "}
                  <span>
                    {" "}
                    Office No M1, 135, EA, Ring Rd, opp. Bombay Hospital Service
                    Road, Scheme No:94, Indore, Madhya Pradesh 452010
                  </span>
                </div>
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 pt-5 pt-md-0">
            <div className="contact-box">
              <div className="contact-box text-center">
                <h3 className="py-4">Contact us via form!</h3>
                {/* Contact Form */}
                <Formik
                  initialValues={initialValues}
                  validate={validate}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form id="contact-form">
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group mb-3">
                            <Field
                              type="text"
                              className="form-control-lg form-control"
                              name="name"
                              placeholder="Name"
                              required="required"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="text-danger text-start m-1"
                            />
                          </div>
                          <div className="form-group mb-3">
                            <Field
                              type="email"
                              className="form-control form-control-lg"
                              name="email"
                              placeholder="Email"
                              required="required"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="text-danger text-start m-1"
                            />
                          </div>
                          <div className="form-group mb-3">
                            <Field
                              type="text"
                              className="form-control form-control-lg"
                              name="subject"
                              placeholder="Subject"
                              required="required"
                            />
                            <ErrorMessage
                              name="subject"
                              component="div"
                              className="text-danger text-start m-1"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <Field
                              as="textarea"
                              className="form-control form-control-lg"
                              name="message"
                              placeholder="Message"
                              required="required"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="text-danger text-start m-1"
                            />
                          </div>
                        </div>
                        <div className="col-12 sendMsg">
                          <button
                            type="submit"
                            className="btn btn-lg mt-3"
                            disabled={isSubmitting}
                          >
                            <span className="text-white pr-3">
                              <img
                                src="Images/sendMessage.svg"
                                alt="Send Message Icon"
                                className="sendMsgIcon"
                              />
                            </span>
                            Send Message
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <p className="form-message" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
