/* eslint-disable jsx-a11y/alt-text */
import react from "react";

export default function Workarea() {
  return (
    <section className="section work-area overflow-hidden ptb_100 bg-orange">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="work-content text-center">
              <h2 className="text-white d-none d-md-block">
                How Soothfy works?
              </h2>
              <h3 className="fw-bolder text-white fs-3 d-block d-md-none">
                How Soothfy works?
              </h3>
              <p className="d-none d-sm-block my-3 mt-sm-4 mb-sm-5 text-white">
                Getting started is easy! Simply download the app, create an
                account, and explore our library of guided yoga meditation
                sessions. One of the unique features of our app is personalized
                meditation recommendations tailored to your preferences and
                goals.
              </p>
              <p className="d-block d-sm-none my-3 text-white">
                Getting started is easy! Simply download the app, create an
                account, and explore our library of guided yoga meditation
                sessions.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="single-work text-center p-3 arrow-class">
              <div className="work-icon">
                <img
                  src="Images/work-icon1.png"
                  className="work-icon-img"
                  alt
                />
              </div>
              <h3 className="py-3 text-white">Install the app</h3>
              <p className="text-white">
                Download Soothy from the App Store or Google Play Store. Create
                your personalized profile.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="single-work text-center p-3 arrow-class">
              <div className="work-icon">
                <img
                  src="Images/work-icon2.png"
                  className="work-icon-img"
                  alt
                />
              </div>
              <h3 className="py-3 text-white">Setup your profile</h3>
              <p className="text-white">
                Set your wellness goals &amp; Explore guided meditation sessions
                and begin your practice.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="single-work text-center p-3">
              <div className="work-icon">
                <img
                  src="Images/work-icon3.png"
                  className="work-icon-img"
                  alt
                />
              </div>
              <h3 className="py-3 text-white">Enjoy the features</h3>
              <p className="text-white">
                Track your progress, receive recommendations, and connect with
                the community for support.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
